import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { Page, Header, DarkModeWrapper } from './styles';
import { theme } from './shared/themes';
import Home from './pages/Home';
import Experience from './pages/Experience';
import Contact from './pages/Contact';
import Projects from './pages/Projects';
import { Topnav } from './components/Topnav';
import { Footer } from './components/Footer';

function App() {
	const [darkMode, setDarkMode] = React.useState(
		localStorage.getItem('darkmode') == null
			? window.matchMedia('(prefers-color-scheme: dark)').matches
			: localStorage.getItem('darkmode') === 'true'
	);

	const toggleDarkMode = (checked) => {
		setDarkMode(checked);
		localStorage.setItem('darkmode', checked);
	};

	return (
		<BrowserRouter>
			<ThemeProvider theme={darkMode ? theme.dark : theme.light}>
				<Page>
					<Header classname='dark'>
						<Topnav />
					</Header>
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/experience' element={<Experience />} />
						<Route path='/projects' element={<Projects />} />
						<Route path='/contact' element={<Contact />} />
					</Routes>
					<DarkModeWrapper>
						<DarkModeSwitch
							checked={darkMode}
							onChange={toggleDarkMode}
							moonColor={theme.dark.highlight}
							size={30}
						/>
					</DarkModeWrapper>
					<Footer />
				</Page>
			</ThemeProvider>
		</BrowserRouter>
	);
}

export default App;
