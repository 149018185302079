export const theme = {
	light: {
		main: '#ffffe4',
		highlight: 'black',
		text: {
			main: 'black',
			opposite: '#ffffe4',
			hover: 'gray',
			active: 'gray',
		},
	},
	dark: {
		main: 'black',
		highlight: '#ffffe4',
		text: {
			main: '#ffffe4',
			opposite: 'black',
			hover: 'gray',
			active: 'gray',
		},
	},
	fonts: ['sans-serif', 'Roboto'],
	fontSizes: {
		small: '12px',
		medium: '16px',
		large: '20px',
	},
};
