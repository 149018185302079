import styled from 'styled-components';
import { fade } from '../../shared/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Wrapper = styled.nav`
	width: 100%;
	height: 10dvh;

	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	animation: 2s ease 0s normal forwards 1 ${fade};

	a {
		margin: 5px;
	}
`;

export const ThemedIcon = styled(FontAwesomeIcon)`
	color: ${({ theme }) => theme.highlight};
`;
