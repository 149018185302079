import React from 'react';
import { Wrapper, ThemedIcon } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faGithubAlt } from '@fortawesome/free-brands-svg-icons';
import { useTheme } from 'styled-components';

export const Footer = () => {
	return (
		<>
			<Wrapper>
				<a
					href='https://linkedin.com/in/martinchuang'
					rel='noopener noreferrer'
					target='_blank'
				>
					<FontAwesomeIcon
						icon={faLinkedinIn}
						size={'2x'}
						color={useTheme().text.main}
					/>
				</a>
				<a
					href='https://github.com/comique'
					rel='noopener noreferrer'
					target='_blank'
				>
					<FontAwesomeIcon
						icon={faGithubAlt}
						size={'2x'}
						color={useTheme().text.main}
					/>
				</a>
				<ThemedIcon icon='fa-brands fa-linkedin-in' />
			</Wrapper>
		</>
	);
};
