import styled from 'styled-components';
import { fade, moveUp } from '../../shared/styles';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: centre;
	align-items: flex-start;

	width: fit-content;
`;

export const Title = styled.h1`
	color: ${({ theme }) => theme.text.main};
	margin: 0;
	font-size: clamp(3.5vw, 60px, 60px);
	text-transform: uppercase;
	animation: 2s ease 0s normal forwards 1 ${fade},
		2s ease 0s normal forwards 1 ${moveUp};
`;

export const Detail = styled.h2`
	color: ${({ theme }) => theme.text.main};
	margin: 0;
	font-size: clamp(1vw, 20px, 20px);
	text-transform: lowercase;
	opacity: 0;
	align-self: ${(props) => props.align};
	animation: 2s ease 1s normal forwards 1 ${fade},
		2s ease 1s normal forwards 1 ${moveUp};
`;
