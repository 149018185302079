import React from 'react';
import {
	Wrapper,
	Detail,
	ProjectCard,
	Name,
	Description,
	ToolsWrapper,
} from './styles';
import { Content } from '../../shared/styles';
import { projects } from '../../assets/projects.js';

class Experience extends React.Component {
	fetchProjects = () => {
		return projects.map((project, key) => {
			return (
				<ProjectCard order={key}>
					<Name>{project.name}</Name>
					<ToolsWrapper>
						{project.tools.map((tool, key) => {
							return <Detail>{tool}</Detail>;
						})}
					</ToolsWrapper>
					<Description>{project.description}</Description>
				</ProjectCard>
			);
		});
	};

	render() {
		return (
			<>
				<Content>
					<Wrapper>{this.fetchProjects()}</Wrapper>
				</Content>
			</>
		);
	}
}

export default Experience;
