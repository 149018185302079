import styled from 'styled-components';
import { fade, moveDown } from '../../shared/styles';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: auto;

	width: fit-content;
`;

export const Name = styled.h1`
	color: ${({ theme }) => theme.text.main};
	margin: 0;
	font-size: 1.5rem;
	text-transform: uppercase;
`;

export const Description = styled.h2`
	color: ${({ theme }) => theme.text.main};
	margin: 0;
	font-size: 0.75rem;
`;

export const Detail = styled.h2`
	color: ${({ theme }) => theme.text.main};
	margin: 0;
	font-size: 0.9rem;
	text-transform: uppercase;

	@ (max-width: 768px) {
		font-size: ${(props) => (props.size ? `0.5rem` : `0.75rem`)};
	}
`;

export const ToolsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 0 12px;
	margin-top: 5px;
	margin-bottom: 5px;
`;

export const ProjectCard = styled.div`
	width: 20vw;
	height: fit-content;

	display: flex;
	flex-direction: column;
	justify-content: centre;
	align-items: centre;

	margin: 1rem 0;
	padding: 1rem;
	opacity: 0;
	animation: 2s ease ${(props) => props.order}s normal forwards 1 ${fade},
		2s ease ${(props) => props.order}s normal forwards 1 ${moveDown};

	border-style: solid;
	border-thickness: 1px;
	border-color: ${({ theme }) => theme.text.main};
	border-radius: 15px;

	@media (max-width: 1400px) {
		width: 31vw;
	}

	@media (max-width: 768px) {
		width: 80vw;
	}
`;
