import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { fade } from '../../shared/styles';

export const Wrapper = styled.nav`
	z-index: 2;
	width: 100%;
	height: ${(props) => (props.open ? `100dvh` : `0%`)};
	background-color: ${({ theme }) => theme.main};
	margin: 0;

	display: flex;
	flex-direction: row;

	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	animation: 2s ease 0s normal forwards 1 ${fade};
	transition: all 0.5s ease;

	@media (min-width: 768px) {
		height: 10dvh;
	}
`;

export const Logo = styled.nav`
	height: 100%;
	width: 100%;
	flex: 1;

	display: flex;
	justify-content: center;
	align-items: center;
`;

export const StyledLink = styled(NavLink)`
	text-decoration: none;
	color: ${({ theme }) => theme.text.main};
	flex: 1;

	margin: 2vh 0;

	&:hover,
	&:focus {
		color: ${({ theme }) => theme.text.hover};
	}

	&.active {
		color: ${({ theme }) => theme.text.active};
	}

	@media (min-width: 768px) {
		margin: 0 2vw;
	}
`;

export const Navbar = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	text-transform: lowercase;
	height: fit-content;
	font-size: 1.5rem;
	position: relative;

	${StyledLink} {
		text-decoration: none;
		opacity: ${(props) => (props.open ? `1` : `0`)};
		transition: all 0.5s ease;
	}

	@media (min-width: 768px) {
		flex-direction: row;
		height: 100%;
		width: fit-content;
		font-size: 1rem;
		position: static;

		${StyledLink} {
			opacity: 1;
		}
	}
`;

export const HamburgerMenu = styled.button`
	position: absolute;
	top: 1rem;
	right: 1rem;
	background-color: transparent;
	border: none;
	cursor: pointer;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */

	@media (min-width: 768px) {
		display: none;
	}
`;
