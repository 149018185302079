import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Wrapper, Navbar, StyledLink, HamburgerMenu } from './styles';

export const Topnav = () => {
	const [navOpen, setNavOpen] = useState(false);

	const toggleMenu = () => {
		setNavOpen(!navOpen);
	};

	return (
		<>
			<Wrapper open={navOpen}>
				<HamburgerMenu onClick={toggleMenu} hidden={navOpen}>
					<FontAwesomeIcon
						icon={faBars}
						size={'2x'}
						color={useTheme().text.main}
					/>
				</HamburgerMenu>
				<HamburgerMenu onClick={toggleMenu} hidden={!navOpen}>
					<FontAwesomeIcon
						icon={faXmark}
						size={'2x'}
						color={useTheme().text.main}
					/>
				</HamburgerMenu>
				<Navbar open={navOpen}>
					<StyledLink to='/' onClick={toggleMenu}>
						Home
					</StyledLink>
					<StyledLink to='/experience' onClick={toggleMenu}>
						Experience
					</StyledLink>
					<StyledLink to='/projects' onClick={toggleMenu}>
						Projects
					</StyledLink>
					<StyledLink to='/contact' onClick={toggleMenu}>
						Contact
					</StyledLink>
				</Navbar>
			</Wrapper>

			<Outlet />
		</>
	);
};
