import styled from 'styled-components';
import { fade } from './shared/styles';

export const Page = styled.div`
	width: 100vw;
	min-height: 100dvh;
	background-color: ${({ theme }) => theme.main};
	transition: all 0.5s ease;
	margin: 0;

	display: flex;
	flex-direction: column;
`;

export const Header = styled.div`
	width: 100%;
	height: 10dvh;
	background-color: ${({ theme }) => theme.main};
	margin: 0;

	display: flex;
	flex-direction: row;
        transition: all 0.5s ease;
`;

export const DarkModeWrapper = styled.div`
	position: fixed;
	bottom: 1rem;
	right: 1rem;
	z-index: 1;
	animation: 2s ease 0s normal forwards 1 ${fade};
`;
