import React from 'react';
import {
	Wrapper,
	ExpCard,
	Position,
	Detail,
	Company,
	InfoWrapper,
	SkillsWrapper,
	Resume,
	ResumeWrapper,
} from './styles';
import { Content } from '../../shared/styles';
import { jobs } from '../../assets/jobInfo.js';
import resume from '../../assets/resume.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

class Experience extends React.Component {
	fetchJobInformation = () => {
		return jobs.map((Job, key) => {
			return (
				<ExpCard>
					<Position>{Job.Position}</Position>
					<Company>{Job.Company}</Company>
					<InfoWrapper>
						<Detail>{Job.Location}</Detail>
						<Detail>{Job.Timeline}</Detail>
					</InfoWrapper>
					<SkillsWrapper>
						{Job.Skills.map((skill, key) => {
							return <Detail size={'small'}>{skill}</Detail>;
						})}
					</SkillsWrapper>
				</ExpCard>
			);
		});
	};

	render() {
		return (
			<>
				<Content>
					<Wrapper>
						{this.fetchJobInformation()}
						<ResumeWrapper>
							<Resume
								href={resume}
								target='_blank'
								rel='noopener noreferrer'
							>
								<b>
									Resume{' '}
									<FontAwesomeIcon
										icon={faArrowUpRightFromSquare}
									/>
								</b>
							</Resume>
						</ResumeWrapper>
					</Wrapper>
				</Content>
			</>
		);
	}
}

export default Experience;
