import styled from 'styled-components';
import { fade } from '../../shared/styles';

export const Card = styled.div`
	background-color: ${({ theme }) => theme.highlight};
	height: fit-content;
	width: 50vw;
	border-radius: 10px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	animation: 1s ease 0s normal forwards 1 ${fade};

	@media (max-width: 768px) {
		width: 80vw;
	}
`;

export const Title = styled.h1`
	font-size=2.5rem;
	text-transform: uppercase;
	opacity: 0;
	animation: 1s ease 1s normal forwards 1 ${fade};
	color: ${({ theme }) => theme.text.opposite};
`;

export const SuccessMessage = styled.span`
	font-size=1.5rem;
	text-transform: uppercase;
`;

export const Form = styled.form`
	width: 75%;
	margin: 2vh 0 5vh 0;
	opacity: 0;
	animation: 1s ease 1s normal forwards 1 ${fade};
`;

export const TextInput = styled.input`
	width: 100%;
`;

export const TextArea = styled.textarea`
	width: 100%;
	height: 15vh;
	resize: none;
`;

export const FormItem = styled.div`
	margin: 2vh 0 2vh 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: ${(props) => (props.align ? props.align : `flex-start`)};
	color: ${({ theme }) => theme.text.opposite};

	${TextArea}, ${TextInput} {
		background-color: transparent;
		border: 1px solid ${({ theme }) => theme.main};
		border-radius: 3px;
		padding: 5px;
		outline: none;
		color: ${({ theme }) => theme.text.opposite};
	}
`;

export const Label = styled.label`
	font-size: 1rem;
	text-transform: uppercase;
	margin-bottom: 5px;
`;

export const Submit = styled.button`
	font-size: 1rem;
	padding: 10px;
	border: 1px solid ${({ theme }) => theme.main};
	border-radius: 3px;
	cursor: pointer;
	background-color: transparent;
	color: ${({ theme }) => theme.text.opposite};
	transition: all 0.3s ease;

	&:hover {
		background-color: ${({ theme }) => theme.main};
		color: ${({ theme }) => theme.text.main};
	}
`;
