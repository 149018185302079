export const projects = [
  {
    name: "haobuys.com",
    description:
      "E-commerce website for selling products to friends and local Taiwanese community based in Toronto with an admin CMS.",
    tools: [
      "NextJS",
      "Bun",
      "TypeScript",
      "CSS",
      "PostgreSQL",
      "Prisma",
      "Docker",
      "Cloudflare Tunnels",
      "Git",
    ],
  },
  {
    name: "Portfolio",
    description:
      "My portfolio website; written in JS using the ReactJS framework.",
    tools: ["React", "Bun", "JavaScript", "CSS", "Git"],
  },
  {
    name: "Discord Bot",
    description:
      "A discord bot written using discord.js to help manage guild members and schedule boss runs in a discord server for a game called MapleStory. The bot is using a SQLite server being served through Bun, running on a docker container hosted on my home server.",
    tools: [
      "discord.js",
      "Bun",
      "SQL",
      "JavaScript",
      "Prisma",
      "Docker",
      "Git",
    ],
  },
  {
    name: "E-mail Scraper",
    description:
      "Automated a Python script to scan through my e-mail for incoming new mail from the scanner, then checks the attachment for the type of document. The script reads the document using OCR and saves the document in the correct directory with the correct respective naming for each file.",
    tools: ["Python", "OCR"],
  },
];
