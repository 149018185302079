import {
	Card,
	Title,
	Form,
	TextInput,
	TextArea,
	Label,
	Submit,
	FormItem,
	SuccessMessage,
} from './styles';
import { Content } from '../../shared/styles';
import React from 'react';
import emailjs from '@emailjs/browser';

class Contact extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fullName: '',
			email: '',
			subject: '',
			message: '',
			sent: false,
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	SERVICE_ID = 'service_0p4jeo8';
	TEMPLATE_ID = 'template_k76uhta';

	handleSubmit = (event) => {
		event.preventDefault();
		emailjs.init();
		emailjs
			.send(
				this.SERVICE_ID,
				this.TEMPLATE_ID,
				this.state,
				'BP85pNmub3D7BPBUK'
			)
			.then(
				(result) => {
					this.setState({
						fullName: '',
						email: '',
						subject: '',
						message: '',
						sent: true,
					});
				},
				(error) => {
					alert('error');
				}
			);
	};

	setFullName = (e) => {
		this.setState({ fullName: e.target.value });
	};

	setEmail = (e) => {
		this.setState({ email: e.target.value });
	};

	setSubject = (e) => {
		this.setState({ subject: e.target.value });
	};

	setMessage = (e) => {
		this.setState({ message: e.target.value });
	};

	render() {
		return (
			<>
				<Content>
					<Card>
						<Title>Contact Me</Title>
						<SuccessMessage hidden={!this.state.sent}>
							<strong>Message Sent</strong>
						</SuccessMessage>
						<Form
							onSubmit={this.handleSubmit}
							ref={this.form}
							autoComplete='on'
						>
							<FormItem>
								<Label>Full Name</Label>
								<TextInput
									type='fullname'
									placeholder='Full Name'
									value={this.state.fullName}
									onChange={this.setFullName}
								/>
							</FormItem>
							<FormItem>
								<Label>E-mail</Label>
								<TextInput
									type='email'
									placeholder='E-mail'
									value={this.state.email}
									onChange={this.setEmail}
								/>
							</FormItem>
							<FormItem>
								<Label>Subject</Label>
								<TextInput
									type='text'
									placeholder='Subject'
									value={this.state.subject}
									onChange={this.setSubject}
								/>
							</FormItem>
							<FormItem>
								<Label>Message</Label>
								<TextArea
									value={this.state.message}
									onChange={this.setMessage}
								/>
							</FormItem>
							<FormItem align={'flex-end'}>
								<Submit>Submit</Submit>
							</FormItem>
						</Form>
					</Card>
				</Content>
			</>
		);
	}
}

export default Contact;
