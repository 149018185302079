import styled, { keyframes } from 'styled-components';

export const Content = styled.div`
	width: 100%;
	min-height: 80dvh;
	max-height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const fade = keyframes`
	0% { opacity: 0; }
	100% { opacity: 1; }
`;

export const moveUp = keyframes`
	0% { transform: translateY(50px); }
	100% { transform: translateY(0px); }
`;

export const moveDown = keyframes`
	from { transform: translateY(-20px); }
	to { transform: translateY(0px); }
`;
