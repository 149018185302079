import React from "react";
import { Wrapper, Title, Detail } from "./styles";
import { Content } from "../../shared/styles";

class Home extends React.Component {
  render() {
    return (
      <>
        <Content>
          <Wrapper>
            <Title>Martin Chuang</Title>
            <Detail>Software Development Engineer</Detail>
            <Detail align={"flex-start"}>Toronto, ON, Canada</Detail>
            <Detail>Est. 1999, Taiwan</Detail>
          </Wrapper>
        </Content>
      </>
    );
  }
}

export default Home;
